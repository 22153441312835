<template>
<div>
  <v-dialog
    v-model="dialog"
    max-width="900"
    scrollable
    persistent
  >
    <v-card :loading="$store.state.loading" :disabled="$store.state.loading">
      <v-card-title>
        <span>
          {{ this.datos.editar ? 'Modificar' : 'Agregar'  }} un Movimiento Bancario
        </span>
        <v-spacer></v-spacer>
        <v-btn title="Cerrar" icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <div class="pt-4 text-center" style="font-size: 1.1em;">Total de la Liquidación: {{ formatMoney(datos.totalLiq) }} - Total Acumulado: {{ /*parseFloat(*/formatMoney(datos.totalAcu)/*.toFixed(2))*/ }} - Pendiente: {{ formatMoney(pendiente) }}</div> 
      <v-spacer></v-spacer>
      <v-card-text>
        <v-form ref="form" class="mt-10">
          <v-row>
            <!-- Tipo Movimiento, formato y tipo de cheque, Comprobante, Importe y Fechas -->
            <v-col cols="6" sm="4" :md="filtro.tipo_mov == 3 ? 2 : 3" class="py-0">
              Movimiento
              <v-autocomplete
                v-model="filtro.tipo_mov"
                item-text="tipo_mov_nombre"
                item-value="tipo_mov_codigo"
                :items="datos.tiposMovBanc"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                hide-details
                @change="limpiar()"
              ></v-autocomplete>
            </v-col>
            <template v-if="filtro.tipo_mov == 3">
              <v-col cols="6" sm="3" md="2" class="py-0">
                Formato
                <v-autocomplete
                  v-model="filtro.formato_cheque"
                  item-text="formato_nombre"
                  item-value="formato_id"
                  :items="datos.formatosCheques"
                  :rules="[rules.required]"
                  
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="py-0">
                Tipo
                <v-autocomplete
                  v-model="filtro.tipo_cheque"
                  item-text="tipo_cheque_nombre"
                  item-value="tipo_cheque_id"
                  :items="datos.tiposCheques"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="5" class="py-0">
                <v-row>
                  <v-col>
                    Emisión
                    <FechaPickerVue
                      v-model="filtro.fecha_emision"
                      :readonly="true"
                      :filled="true"
                    />
                  </v-col>
                  <v-col>
                    Vencimiento
                    <FechaPickerVue
                      v-model="filtro.fecha_vencimiento"
                      :readonly="true"
                      :filled="true"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>
            <v-col cols="6" sm="4" md="3" class="py-0">
              Nº Comprobante
              <v-text-field
                v-model.trim="filtro.num_comp"
                outlined
                type="number"
                :readonly="filtro.tipo_mov != 3 ? true : false"
                :filled="filtro.tipo_mov != 3 ? true : false"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              Importe
              <TextFieldMoneyVue
                v-model="importeActual"
                v-bind:properties="{
                  prefix: '$',
                  type: 'number',
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 2,
                  empty: null
                }"
                
              />
              <!-- @blur="datos.editar ? editarImporte() : acumularImporte()" filtro.importe 
              @change="datos.editar ? editarImporte() : acumularImporte()"
              -->
            </v-col>
            <v-col v-if="filtro.tipo_mov != 3" cols="6" sm="3" class="py-0">
              Fecha
              <FechaPickerVue
                v-model="filtro.fecha"
                :readonly="true"
                :filled="true"
              />
            </v-col>
            <!-- Banco / Cuenta / CBU -->
            <v-col cols="12" sm="4" md="3" class="py-0">
              {{ filtro.tipo_mov == 3 ? 'Banco Emisor' : 'Banco' }}
              <v-autocomplete
                v-if="filtro.tipo_mov == 3"
                v-model="filtro.banco_pais"
                item-text="banco_pais_nombre"
                item-value="banco_pais_id"
                :items="datos.bancosPais"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="filtro.cuenta = null; filtro.cbu = null"
              ></v-autocomplete>
              <v-autocomplete
                v-else
                v-model="filtro.banco"
                item-text="banco_nombre"
                item-value="banco_codigo"
                :items="datos.bancos"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="obtenerCuentas(); filtro.cuenta = null; filtro.cbu = null"
              ></v-autocomplete>
            </v-col>
            <template v-if="filtro.tipo_mov != 3">
              <v-col cols="12" sm="4" md="3" class="py-0">
                Cuenta
                <v-autocomplete
                  v-model="filtro.cuenta"
                  item-text="nro_cuenta"
                  item-value="cuenta_codigo"
                  :items="cuentas"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                  @change="setCBU()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                CBU
                <v-text-field
                  v-model="filtro.cbu"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </template>
            <!-- Beneficiario -->
            <template v-if="filtro.tipo_mov == 3">
              <v-col cols="6" sm="4" class="py-0">
                Beneficiario
                <v-text-field
                  v-model.trim="filtro.beneficiario"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="py-0">
                Beneficiario CUIT
                <v-text-field-integer
                  v-model="filtro.beneficiario_cuit"
                  v-bind:properties="{
                    type: 'tel',
                    'validate-on-blur': true,
                    outlined: true,
                    dense: true
                  }"
                  v-bind:options="{
                    inputMask: '##-########-#',
                    outputMask: '###########',
                    empty: null
                  }"
                />
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmarVue
          :loading="load"
          :disabled="bloquear"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>  
</template>

<script>
import moment from 'moment'
import BtnConfirmarVue from '../util/BtnConfirmar.vue'
import FechaPickerVue from '../util/FechaPicker.vue'
import TextFieldMoneyVue from '../util/TextFieldMoney.vue'
import { format_money, roundNumber } from '../../util/utils'



export default {
  name: 'ModalAgregarEditarMovBanc',
  data(){
    return{
      c: 0, //para renderizar
      formatMoney: format_money,
      moment: moment,
      load: false,
      bloquear: false,
      ultAcumulado: 0,
      acumuladoActual: 0,
      primerAcumuladoBand: 0,
      pendienteNegativo: false,
      utlImporte: 0,
      importeActual: 0,
      pendiente: null,
      pendienteAux: 0,
      filtro:{
        comprobante_id: null,
        tipo_mov: null,
        formato_cheque: null,
        tipo_cheque: null,
        fecha_emision: null,
        fecha_vencimiento: null,
        fecha: null,
        num_comp: null,
        importe: null,
        banco: null,
        banco_pais: null,
        cuenta: null,
        cbu: null,
        beneficiario: null,
        beneficiario_cuit: null,
        emisor: null,
        origen_deposito: 4,
      },
      filtroCopia:{},
      filtroCopia2:{},
      rules: {
        required: value => !!value || 'Campo requerido',
        max: value => (value ? value.length : 0) <= 50 || 'Máximo 50 caracteres'
      },
      //getCuentasBanco
      cuentas:[],
    }
  },
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default:{}
    }
  },
  computed:{
    dialog:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    },
  },
  methods:{
    async init(){
      this.filtro.num_comp = this.datos.numero_comprobante;
      this.filtro.fecha = this.datos.fecha;
      this.filtro.fecha_emision = this.datos.fecha;
      this.filtro.fecha_vencimiento = this.datos.fecha;
      this.filtro.comprobante_id = this.datos.comprobante_id;
      this.ultAcumulado = this.datos.totalAcu;
      this.primerAcumuladoBand = 0;
      this.utlImporte = 0;
      this.pendiente = parseFloat((this.datos.totalLiq - this.datos.totalAcu).toFixed(2));
      if(this.datos.editar){
        this.utlImporte = this.datos.importe;
        this.filtro.fecha = this.datos.fecha;
        this.filtro.fecha_emision = this.datos.fecha_emision;
        this.filtro.fecha_vencimiento = this.datos.fecha_vencimiento;
        this.pendiente = parseFloat((this.datos.totalLiq - this.datos.totalAcu).toFixed(2));
        this.filtro.tipo_mov = this.datos.tipo_mov;
        this.importeActual = this.datos.importe;
        this.utlImporte = this.importeActual;
        this.filtro.banco = this.datos.banco;
        this.filtro.banco_pais = this.datos.banco_pais;
        if(this.datos.tipo_mov != 3){ //si es transfer o dep, rellena el CBU
          this.filtro.cuenta = this.datos.cuenta;
          await this.obtCuentasCbu();
        }
        this.filtro.formato_cheque = this.datos.formato;
        this.filtro.tipo_cheque = this.datos.tipoCheque;
        this.filtro.beneficiario = this.datos.beneficiario;
        this.filtro.beneficiario_cuit = this.datos.beneficiario_cuit;
      }else{
        this.filtro.tipo_mov = null;
        this.importeActual = null;
        this.filtro.banco = null;
        this.filtro.banco_pais = null;
        this.filtro.cuenta = null;
        this.filtro.cbu = null;
        this.filtro.formato_cheque = null;
        this.filtro.tipo_cheque = null;
        this.filtro.beneficiario = null;
        this.filtro.beneficiario_cuit = null;
      }
      this.filtroCopia = JSON.parse(JSON.stringify(this.filtro))
    },
    validadCamposCheques(){
      let error = {};
      if(!this.filtro.formato_cheque){
        error.text = 'Debe seleccionar un formato.'
        error.color = 'warning'
        return error;
      }
      if(!this.filtro.tipo_cheque){
        error.text = 'Debe seleccionar un tipo de cheque.'
        error.color = 'warning'
        return error;
      }
      if(!this.importeActual || roundNumber(this.importeActual,2) == 0){
        error.text = 'Debe ingresar un importe mayor que cero.'
        error.color = 'warning'
        return error;
      }
      if(!this.filtro.banco_pais){
        error.text = 'Debe seleccionar un banco disponible.'
        error.color = 'warning'
        return error;
      }
      if(!this.filtro.beneficiario){
        error.text = 'Debe ingresar un beneficiario.'
        error.color = 'warning'
        return error;
      }
      if(!this.filtro.beneficiario_cuit){
        error.text = 'Debe ingresar cuit del beneficiario.'
        error.color = 'warning'
        return error;
      }        
      if(this.filtro.beneficiario_cuit.length < 11){
        error.text = 'La longitud del cuit del beneficiario es incorrecta.'
        error.color = 'warning'
        return error;
      }
      return error;
    },
    validadCamposNoCheques(){
      let error = {};

      if(!this.importeActual || roundNumber(this.importeActual,2) == 0){
        error.text = 'Debe ingresar un importe';
        error.color = 'warning'
        return error
      }
      if(!this.filtro.banco){
        error.text = 'Debe seleccionar un banco disponible';
        error.color = 'warning'
        return error
      }
      if(!this.filtro.cuenta){
        error.text = 'Debe seleccionar una cuenta disponible';
        error.color = 'warning'
        return error
      }
      return error;
    },
    guardar(){
      if(!this.filtro.tipo_mov){
        this.$swal.fire({
          icon: 'warning',
          title: `Confirmación de Movimiento`,
          text: 'Debe seleccionar un movimiento',
        })
      }else{
        
        this.calcularTotales();

        if(this.pendienteNegativo){
          //mensaje
          this.$swal.fire({
            icon: 'info',
            title: `Importe no Válido`,
            text: 'El importe ingresado no es válido ya que sumado al importe parcial sobrepasa al total del neto de la Liquidación.', 
          })
          //this.bloquear = true; 
          this.importeActual = null;
          return 
        }

        this.primerAcumuladoBand = 0;
        this.filtro.importe = this.importeActual;
        
        this.filtro.id_interno = this.datos.id_interno;
        
        let error;
        if(this.filtro.tipo_mov == 3){  //CHEQUE
          error = {};
          error = this.validadCamposCheques();
          if(Object.keys(error).length != 0){
            return this.$store.dispatch('show_snackbar', error)
          }
          //guardar
          if(!this.datos.editar){
            this.filtro.banco = this.datos.bancosPais.filter(elem => elem.banco_pais_id == this.filtro.banco_pais)[0].banco_id;
            this.filtro.cuenta = 0;
            this.$emit('agregarMov', this.filtro);
          }else{
            this.$emit('editarMov', this.filtro);
          }
        }else{ //1 y 2
          error = {};
          error = this.validadCamposNoCheques();
          if(Object.keys(error).length != 0){
            return this.$store.dispatch('show_snackbar', error)
          }
          //guardar
          this.datos.editar? this.$emit('editarMov', this.filtro) : this.$emit('agregarMov', this.filtro);
        }
      }
    },
    calcularTotales(){
      if(this.datos.editar){
        this.datos.totalAcu -= this.datos.importe;
      }
      if(roundNumber(this.datos.totalAcu + this.importeActual,2) <= roundNumber(this.datos.totalLiq,2)){
        this.datos.totalAcu += this.importeActual;
        this.pendiente = this.datos.totalLiq - this.datos.totalAcu;
        this.pendienteNegativo = false
      }else{
        this.pendienteNegativo = true
      }

    },
    acumularImporte(){
      if(parseFloat(this.importeActual) >= 0){
        this.ultAcumulado = this.datos.totalAcu; //último acumulado

        if(this.primerAcumuladoBand == 1){
          this.datos.totalAcu -= this.utlImporte
        }

        if(/*roundNumber(this.pendiente,2) <= 0 &&*/ roundNumber(this.datos.totalAcu + this.importeActual,2) > roundNumber(this.datos.totalLiq,2)/* &&
          parseFloat(this.utlImporte) != parseFloat(this.importeActual)*/){
          //mensaje
          this.$swal.fire({
            icon: 'info',
            title: `Importe no Válido`,
            text: 'El importe ingresado no es válido ya que sumado al importe parcial sobrepasa al total del neto de la Liquidación.', 
          })
          this.bloquear = true;
          //this.datos.totalAcu = this.ultAcumulado; //resetea valores
          //this.pendiente = /*parseFloat((*/this.datos.totalLiq - this.datos.totalAcu//).toFixed(2));
          //this.importeActual = null;
          return 
        }else{
          this.bloquear = false;
          if(this.primerAcumuladoBand == 0){
            this.datos.totalAcu += this.importeActual;
            this.primerAcumuladoBand = 1;
            this.utlImporte = this.importeActual // último importe del filtro
            this.pendiente = /*parseFloat((*/this.datos.totalLiq - this.datos.totalAcu//).toFixed(2));
            this.ultAcumulado = this.datos.totalAcu;
          }else{
            if(parseFloat(this.utlImporte) != parseFloat(this.importeActual)){
              //this.datos.totalAcu -= this.utlImporte //resetea valores
              this.datos.totalAcu += this.importeActual;
              this.utlImporte = this.importeActual // último importe del filtro
              this.pendiente = this.datos.totalLiq - this.datos.totalAcu; //*parseFloat((this.datos.totalLiq - this.datos.totalAcu//).toFixed(2));
            }
          }
        }
      }else{
        this.importeActual = null;
        return
      }
    },
    editarImporte(){
      if(this.importeActual >= 0){
          this.acumuladoActual = this.datos.totalAcu;
          this.c += 1;
          
          if(this.primerAcumuladoBand == 0){
            this.utlImporte = this.datos.importe;
            this.ultAcumulado = this.datos.totalAcu - this.utlImporte;
            if(roundNumber(this.utlImporte,2) < roundNumber(this.importeActual,2) && roundNumber(this.pendiente,2) == 0){
              return  this.$swal.fire({
                        icon: 'info',
                        title: `Importe no Válido`,
                        text: 'El importe nuevo ingresado no es válido.', 
                      })
            }
          }else{
            this.ultAcumulado = this.datos.totalAcu - this.importeActual
          }
          if(roundNumber(this.datos.totalAcu + this.importeActual,2) > roundNumber(this.datos.totalLiq,2) &&
             parseFloat(this.utlImporte) != parseFloat(this.importeActual)){
            //mensaje
            this.$swal.fire({
              icon: 'info',
              title: `Importe no Válido`,
              text: 'El importe ingresado no es válido ya que sumado al importe parcial sobrepasa al total del neto de la Liquidación.', 
            })
            this.bloquear = true; 
            this.importeActual = null;
          }else{
            this.bloquear = false;
            if(this.primerAcumuladoBand == 0){
              this.datos.totalAcu = this.ultAcumulado + this.importeActual;
              this.primerAcumuladoBand = 1;
              
              this.utlImporte = this.importeActual // último importe del filtro
              this.pendiente = /*parseFloat((*/this.datos.totalLiq - this.datos.totalAcu//).toFixed(2));
              this.ultAcumulado = this.datos.totalAcu;
            }else{
              if(parseFloat(this.utlImporte) != parseFloat(this.importeActual)){
                this.datos.totalAcu -= this.utlImporte //resetea valores
                this.datos.totalAcu += this.importeActual;
                this.utlImporte = this.importeActual // último importe del filtro
                this.pendiente = this.datos.totalLiq - this.datos.totalAcu; //*parseFloat((*/this.datos.totalLiq - this.datos.totalAcu//).toFixed(2));
              }
            }
          }

      }else{
        this.importeActual = null
        return
      }
    },
    limpiar(){
      this.filtro.formato_cheque = null; 
      this.filtro.tipo_cheque =  null;
      //if(this.filtro.tipo_mov == 3){
        this.filtro.fecha = moment(new Date()).format('DD/MM/YYYY')
        this.filtro.fecha_emision = moment(new Date()).format('DD/MM/YYYY');
        this.filtro.fecha_vencimiento  = moment(new Date()).format('DD/MM/YYYY')
      //}

      this.filtro.num_comp = this.filtroCopia.num_comp;
      this.importeActual =  null;
      this.filtro.banco = null;
      this.filtro.banco_pais = null;
      this.filtro.cuenta = null;
      this.filtro.cbu = null;
      this.filtro.beneficiario = null;
      this.filtro.beneficiario_cuit = null;
      this.filtro.emisor = null;
      this.filtro.origen_deposito = 4;
      this.cuentas = [];
      this.datos.totalAcu = this.ultAcumulado;
      this.pendiente = this.datos.totalLiq - this.datos.totalAcu;
    },
    async obtenerCuentas(){
      this.filtro.cuenta = null;
      this.filtro.cbu = null;
      this.cuentas = [];
      if(this.filtro.banco != null){
        this.$store.state.loading = true;
        await this.$store.dispatch('conciliacionManual/getCuentasBanco',this.filtro.banco)
        .then((res) => {
          this.cuentas = res.cuentas
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
        this.$store.state.loading = false;
      }
    },
    async obtCuentasCbu(){
      this.$store.state.loading = true;
      await this.$store.dispatch('conciliacionManual/getCuentasBanco',this.filtro.banco)
      .then((res) => {
        this.cuentas = res.cuentas
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
      this.$store.state.loading = false;
      this.setCBU();
    },
    setCBU(){
      this.filtro.cbu = (this.cuentas.filter(elem => elem.cuenta_codigo == this.filtro.cuenta))[0].cuenta_cbu;
    }
  },
  components:{
    BtnConfirmarVue, TextFieldMoneyVue, FechaPickerVue
  },
  watch:{
    async dialog(val){
      if(val){
        this.init();
      }else{
        this.limpiar();
        this.filtro = JSON.parse(JSON.stringify(this.filtroCopia));
        this.$refs.form.resetValidation();
      }
    }
  }
}
</script>

<style>

</style>